<template>
  <div>
    <div class="bg-white">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="全部" name="全部">
          <div class="flex aligin-items-center">
            <div>
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="margin-left-50">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="选择日期" size="mini" />
            </div>
          </div>

          <div class="padding-top">
            <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;" size="small">
              <el-radio-button :label="false">总览</el-radio-button>
              <el-radio-button :label="true">按员工查看</el-radio-button>
            </el-radio-group>

            <div class="flex aligin-items-center padding">
              <div><i class="el-icon-pie-chart text-green" style="font-size: 60px;"></i></div>
              <div class="margin-left-lg flex flex-direction justify-content-between" style="height: 60px;">
                <span>总联系次数</span>
                <span>
                  <span class="text-bold" style="font-size: 30px;">0</span>
                  <span class="margin-left-50">0%</span>
                </span>
              </div>
            </div>
            <el-divider></el-divider>
          </div>

          <div>
            <span class="text-bold text-black text-lg flex aligin-items-center">
              各联系方式统计
              <i class="el-icon-question text-grey el-icon--right"></i>
            </span>

            <div class=" width-100 flex-wrap-wrap flex">
              <div class="border-right-grey padding-sm margin-top" v-for="item in sortList" style="width: 25%;">
                <div class="padding-lr-sm">
                  <div>{{ item.name }}</div>
                  <div class="flex justify-content-between margin-top aligin-items-center">
                    <span class="text-bold" style="font-size: 30px;">{{ item.num }}</span>
                    <span>{{ item.percen }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="电话" name="电话">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25">
              通话时长
              <el-select v-model="value" placeholder="全部" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="width-25">
              通话方式
              <el-select v-model="value" placeholder="不限" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="width-25 margin-top">
              分类
              <el-select v-model="value" placeholder="不限" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="width-25 margin-top flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="请输入内容" v-model="keyInput" size="mini" style="width: 200px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div>
            <div class=" width-100 flex-wrap-wrap flex">
              <div class="border-right-grey padding-sm margin-top" v-for="item in phoneList" style="width: 25%;">
                <div class="padding-lr-sm">
                  <div>{{ item.name }}</div>
                  <div class="flex justify-content-between margin-top aligin-items-center">
                    <span class="text-bold" style="font-size: 30px;">{{ item.num }}</span>
                    <span>{{ item.percen }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="电话号码"></el-table-column>
              <el-table-column prop="address" label="通话时长"></el-table-column>
              <el-table-column prop="address" label="通话方式"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="电话录音"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="短信" name="短信">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25">
              短信类型
              <el-select v-model="value" placeholder="不限" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="width-25 flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="联系人或公司电话号码" v-model="keyInput" size="mini" style="width: 220px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div>
            <div class=" width-100 flex-wrap-wrap flex">
              <div class="border-right-grey padding-sm margin-top" v-for="item in messageList" style="width: 25%;">
                <div class="padding-lr-sm">
                  <div>{{ item.name }}</div>
                  <div class="flex justify-content-between margin-top aligin-items-center">
                    <span class="text-bold" style="font-size: 30px;">{{ item.num }}</span>
                    <span>{{ item.percen }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="电话号码"></el-table-column>
              <el-table-column prop="address" label="短信类型"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="短信内容"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="微信" name="微信">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25 flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="联系人或公司电话号码" v-model="keyInput" size="mini" style="width: 220px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div class=" width-100 flex-wrap-wrap flex">
            <div class="padding-sm margin-top" style="width: 25%;">
              <div class="padding-lr-sm">
                <div>微信联系次数</div>
                <div class="flex justify-content-between margin-top aligin-items-center">
                  <span class="text-bold" style="font-size: 30px;">0</span>
                  <span>0%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="微信昵称"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="微信会话记录"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="QQ" name="QQ">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25 flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="联系人或公司电话号码" v-model="keyInput" size="mini" style="width: 220px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div class=" width-100 flex-wrap-wrap flex">
            <div class="padding-sm margin-top" style="width: 25%;">
              <div class="padding-lr-sm">
                <div>QQ联系次数</div>
                <div class="flex justify-content-between margin-top aligin-items-center">
                  <span class="text-bold" style="font-size: 30px;">0</span>
                  <span>0%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="QQ昵称"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="QQ会话记录"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="邮件" name="邮件">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25 flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="联系人或公司电话号码" v-model="keyInput" size="mini" style="width: 220px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div class=" width-100 flex-wrap-wrap flex">
            <div class="padding-sm margin-top" style="width: 25%;">
              <div class="padding-lr-sm">
                <div>邮件联系次数</div>
                <div class="flex justify-content-between margin-top aligin-items-center">
                  <span class="text-bold" style="font-size: 30px;">0</span>
                  <span>0%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="邮箱"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="邮件标题"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="拜访" name="拜访">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25">
              签到事件
              <el-select v-model="value" placeholder="不限" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="width-25 flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="联系人或公司电话号码" v-model="keyInput" size="mini" style="width: 220px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div class="padding-top">
            <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;" size="small">
              <el-radio-button :label="false">按记录查看</el-radio-button>
              <el-radio-button :label="true">按地图查看</el-radio-button>
            </el-radio-group>
          
            <div class=" width-100 flex-wrap-wrap flex">
              <div class="padding-sm border-right-grey" v-for="item in visitList" style="width: 25%;">
                <div class="padding-lr-sm">
                  <div>{{item.name}}</div>
                  <div class="flex justify-content-between margin-top aligin-items-center">
                    <span class="text-bold" style="font-size: 30px;">{{item.num}}</span>
                    <span>{{item.percen}}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="签到事件"></el-table-column>
              <el-table-column prop="address" label="时长"></el-table-column>
              <el-table-column prop="address" label="签退状态"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="签到记录"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="跟进记录">
          <div class="flex aligin-items-center flex-wrap-wrap width-100">
            <div class="width-25">
              员工/部门
              <el-input v-model="staffInput" placeholder="全部员工" style="width: 200px;" size="mini" />
            </div>
            <div class="width-25">
              时间
              <el-date-picker v-model="dateValue" type="date" placeholder="今天" size="mini" style="width: 200px;" />
            </div>
            <div class="width-25 flex aligin-items-center">
              <span class="margin-right-xs">关键词</span>
              <el-input placeholder="联系人或公司电话号码" v-model="keyInput" size="mini" style="width: 220px;"><el-button slot="append" icon="el-icon-search" /></el-input>
            </div>
          </div>
          <div class=" width-100 flex-wrap-wrap flex">
            <div class="padding-sm margin-top" style="width: 25%;">
              <div class="padding-lr-sm">
                <div>跟进记录次数</div>
                <div class="flex justify-content-between margin-top aligin-items-center">
                  <span class="text-bold" style="font-size: 30px;">0</span>
                  <span>0%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-top-sm">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="date" label="联系时间" width="180"></el-table-column>
              <el-table-column prop="name" label="联系人" width="180"></el-table-column>
              <el-table-column prop="address" label="公司"></el-table-column>
              <el-table-column prop="address" label="员工"></el-table-column>
              <el-table-column prop="address" label="跟进记录"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: '全部',
      staffInput: '',
      dateValue: '',
      isCollapse: false,
      sortList: [
        {
          name: '电话',
          num: '0',
          percen: '0'
        },
        {
          name: '短信',
          num: '0',
          percen: '0'
        },
        {
          name: '微信',
          num: '0',
          percen: '0'
        },
        {
          name: 'QQ',
          num: '0',
          percen: '0'
        },
        {
          name: '邮件',
          num: '0',
          percen: '0'
        },
        {
          name: '拜访',
          num: '0',
          percen: '0'
        },
        {
          name: '跟进',
          num: '0',
          percen: '0'
        }
      ],
      phoneList: [
        {
          name: '通话次数',
          num: '0',
          percen: '0'
        },
        {
          name: '通话时长',
          num: '0″',
          percen: '0'
        },
        {
          name: '平均通话时长',
          num: '0″',
          percen: '0'
        },
        {
          name: '接通率',
          num: '0%',
          percen: '0'
        }
      ],
      keyInput: '',
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        }
      ],
      value: '',
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海'
        }
      ],
      messageList: [ {
          name: '发送次数',
          num: '0',
          percen: '0'
        },
        {
          name: '普通短信',
          num: '0',
          percen: '0'
        },
        {
          name: '挂机短信',
          num: '0',
          percen: '0'
        },
        {
          name: '场景短信',
          num: '0%',
          percen: '0'
        }],
        visitList: [{
          name: '拜访次数',
          num: '0',
          percen: '0'
        },
        {
          name: '拜访时长',
          num: '0″',
          percen: '0'
        },]
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleClick(tab, event) { 
    }
  }
};
</script>

<style scoped>
.margin-left-80 {
  margin-left: 100px;
}
>>> .el-select .el-input {
  width: 200px !important;
}
</style>
